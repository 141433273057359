(function () {
  var a = setInterval(function () {
    if (typeof window.jQuery === 'undefined' && typeof window.$ === 'undefined') {
      return;
    }
    clearInterval(a);

    jQuery(function () {
      // MOD2A Banner Interna
      if ($('.MODbannerSlick').length) {
        $('.MODbannerSlick').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 500,
          fade: true,
          arrows: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 10000,
          pauseOnFocus: false,
          pauseOnHover: false,
          responsive: [
            {
              breakpoint: 960,
              settings: {
                arrows: false,
                dots: true,
                autoplaySpeed: 10000,
              },
            },
          ],
        });
      }

      if ($('.banner.ps .mobile-fundo').length <= 0 && $(window).width() <= 992) {
        $('.banner.ps').addClass('banneroff');
      } else {
        $('.banner.ps').addClass('banneron');
      }

      if ($('.banner.home .mobile-fundo').length <= 0 && $(window).width() <= 992) {
        $('.banner.home').addClass('banneroff');
      } else {
        $('.banner.home').addClass('banneron');
      }
      // MOD2A Banner Interna

      $('li.clickDropdown a').on('click', function () {
        $('div#wrapper-menu-dropdown').slideToggle();
        $('li.clickDropdown img').toggle();
        return false;
      })

      $('.abrir-menu').on('click', function () {
        $(this).toggleClass('ativo');
        $('body').toggleClass('blockBody-menu');
        $('.wrapper-lista-menu').slideToggle();
        return false;
      })

      $('.abre-sub-menu').on('click', function () {
        $(this).next('div').slideToggle()
        $(this).find('img').toggle()
      })

      $('.abre-sub-menu-interno').on('click', function () {
        $(this).find('img.change').toggle()
        $(this).parent().parent().parent().first().find('.sub-menu-open').slideToggle()
      })

      if ($('input[type="tel"]').length) {
        $('input[type="tel"]').mask('(99) 99999-9999');
      }

      if ($('input[type="phone"]').length) {
        $('input[type="phone"]').mask('(99) 99999-9999');
      }

      if ($(".n-barra-rotacional-container").length) {
        $(".n-barra-rotacional-container").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000
        });
        $(".slick-prev.slick-arrow").text("");
        $(".slick-next.slick-arrow").text("");
      }

      $(".n-barra-rotacional-fechar").click(function () {
        $(".n-barra-rotacional").fadeOut(200);
        $('.audio_controls').removeClass('comrotacional');
      });

      if ($('#vitrine-destaques-galeria-d').length) {
        $('#vitrine-destaques-galeria-d').slick({
          arrows: false,
          autoplay: true,
          autoplaySpeed: 6000
        });
        $('.flex-control-nav a').click(function (e) {
          e.preventDefault();
          var slide = $(this).attr('data-slider');
          $('#vitrine-destaques-galeria-d').slick('slickGoTo', slide);
          $('.flex-control-nav a').removeClass('flex-active');
          $(this).addClass('flex-active');
        });
      }

      if ($('#vitrine-destaques-galeria-m').length) {
        $('#vitrine-destaques-galeria-m').slick({
          arrows: false,
          autoplay: true,
          autoplaySpeed: 6000
        });
        $('.flex-control-nav a').click(function (e) {
          e.preventDefault();
          var slide = $(this).attr('data-slider');
          $('#vitrine-destaques-galeria-m').slick('slickGoTo', slide);
          $('.flex-control-nav a').removeClass('flex-active');
          $(this).addClass('flex-active');
        });
      }

      if (document.querySelector('#brazil-map')) {
        $('#brazil-map')?.vectorMap({
          map: 'brazil',
          zoomButtons: false,
          zoomMax: 1,
          zoomOnScroll: false,

          regionStyle: {
            initial: {
              fill: '#2c9dce',
              'fill-opacity': 1,
              stroke: '#f7f7f7',
              'stroke-width': 250,
              'stroke-opacity': 1
            },
            hover: {
              fill: '#344156',
              "fill-opacity": 0.8,
              cursor: 'pointer'
            }
          },
          backgroundColor: 'none'
        });
      }
    });

    $(window).bind("scroll", function () {
      if ($(window).scrollTop() > 50) {
        $(".n-privacidade-menu-titulo").addClass("n-pp-menu-mobile").fadeIn("slow");
      } else {
        $(".n-privacidade-menu-titulo").removeClass("n-pp-menu-mobile");
      }
    });

    // Módulo Formas de Ingresso
    $(document).ready(function () {

      if ($("#itens-por-pagina").length) {
        $("#itens-por-pagina").on('change', function () {
          url = location.href;
          let query = location.search.slice(1);
          let partes = query.split("&");
          let data = {};
          partes.forEach(function (parte) {
            let chaveValor = parte.split("=");
            let chave = chaveValor[0];
            let valor = chaveValor[1];
            data[chave] = valor;
          });
          if (url.indexOf("areas") != -1 || url.indexOf("uf") != -1 || url.indexOf("busca") != -1 || url.indexOf("nome") != -1 || url.indexOf("pagina") != -1 || url.indexOf("paginas") != -1) {
            auxiliar = "&";
            auxliarExclude = "&";
          } else {
            auxiliar = "?&";
            auxliarExclude = "?";
          }
          paginas = auxliarExclude + "paginas=" + data["paginas"];
          url = url.replace(paginas, "");
          itens = $(this).val();
          window.location.replace(url + auxiliar + "paginas=" + itens);
        });
      }

      if ($("#itens-por-pagina option").length) {
        $("#itens-por-pagina option").each(function () {
          let query = location.search.slice(1);
          let partes = query.split("&");
          let data = {};
          partes.forEach(function (parte) {
            let chaveValor = parte.split("=");
            let chave = chaveValor[0];
            let valor = chaveValor[1];
            data[chave] = valor;
          });
          if (data["paginas"] == $(this).val()) {
            $(this).prop("selected", true);
          }
        });
      }

      $('#ver-mais-vitrine').on('click', function () {
        document.getElementById('texto-abrir').style.maxHeight = '100%';
        $('#texto-abrir').addClass('animated fadeIn');
        $('#ver-menos-vitrine').show();
        $('#ver-mais-vitrine').hide();
      });

      $('#ver-menos-vitrine').on('click', function () {
        document.getElementById('texto-abrir').style.maxHeight = '106px';
        $('#ver-mais-vitrine').show();
        $('#ver-menos-vitrine').hide();
      });

      if ($('.containerFiltro h3').length && $(window).width() < 960) {
        $('.containerFiltro h3').on('click', function (e) {
          e.preventDefault();
          $(this).toggleClass('filterOpen');
          $(this).next().toggleClass('contentFilterOpen');
        });
      }

      $('.containerFiltro').on('click', function (e) {
        if ($('.containerFiltro input:checked').length > 0) {
          $('.containerBtnsFiltro button').addClass('btnFadeIn');
        } else {
          $('.containerBtnsFiltro button').removeClass('btnFadeIn');
        }
      });

      if ($('.search').length) {
        var url_string = window.location.href;
        var url = new URL(url_string);
        var nome = url.searchParams.get("nome");
        if (nome) {
          $('.search').val(nome);
        }
      }

      if ($(".btnFiltrar").length) {
        url = " ";
        areas = "";
        campus = "";
        periodo = "";
        modalidade = "";

        $('.containerFiltro input[type="checkbox"]').on('change', function () {
          if ($('.containerFiltro input[type="checkbox"]:checked').length) {

          }
        });

        $(".btnFiltrar").on("click", function () {
          $("#areas input:checked").each(function () {
            areas = areas + $(this).val() + "!";
          });
          $("#campus input:checked").each(function () {
            campus = campus + $(this).val() + "!";
          });
          $("#periodo input:checked").each(function () {
            periodo = periodo + $(this).val() + "!";
          });
          $("#modalidade input:checked").each(function () {
            modalidade = modalidade + $(this).val() + "!";
          });

          var arr = [];
          var link = arr.toString();
          var link = link.replace(/,/g, "&");
          var link = "/?" + link;
          var url = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname;
          areas = "?areas=" + areas;
          campus = "&campus=" + campus;
          periodo = "&periodo=" + periodo;
          modalidade = "&modalidade=" + modalidade;
          window.location.replace(url + areas + campus + periodo + modalidade);
        });

      }

      var modalidadesOpcoes = document.querySelectorAll('.modalidades-opcoes');
      for (let index = 0; index < modalidadesOpcoes.length; index++) {
        modalidadesOpcoes[index].addEventListener('click', (event) => {
          var valorSelecionado = event.target.value;
          var modalidadeUrl = new URLSearchParams(window.location.search).get('modalidade');
          if (modalidadeUrl && modalidadeUrl.indexOf(valorSelecionado) !== -1) {
            var modalidade = modalidadeUrl.replace(valorSelecionado + "!", '');
          } else if (modalidadeUrl) {
            var modalidade = modalidadeUrl + valorSelecionado + "!";
          } else {
            var modalidade = valorSelecionado + "!";
          }
          var params = new URLSearchParams(window.location.search);
          params.set('modalidade', modalidade);
          if ($('.vitrine-cursos-geralpolo').length) {
            params.set('ancora', 'CursosPolo');
          }
          window.location.search = params.toString();
        });
      }

      url = window.location.href;

      if (url.match(/unicid.edu.br/) || url.match(/localhost:8002/)) {
        coEmpresa = 'unicid';
      } else if (url.match(/cruzeirodosulvirtual.com.br/) || url.match(/localhost:8014/)) {
        coEmpresa = 'cruzeirovirtual';
      } else if (url.match(/cruzeirodosul.edu.br/) || url.match(/localhost:8003/)) {
        coEmpresa = 'cruzeiro';
      } else if (url.match(/unifran.edu.br/) || url.match(/localhost:8004/)) {
        coEmpresa = 'unifran';
      } else if (url.match(/ceunsp.edu.br/) || url.match(/localhost:8006/)) {
        coEmpresa = 'ceunsp';
      } else if (url.match(/unipe.edu.br/) || url.match(/localhost:8005/)) {
        coEmpresa = 'unipe';
      } else if (url.match(/udf.edu.br/) || url.match(/localhost:8007/)) {
        coEmpresa = 'udf';
      } else if (url.match(/cesuca.edu.br/) || url.match(/localhost:8008/)) {
        coEmpresa = 'cesuca';
      } else if (url.match(/modulo.edu.br/) || url.match(/localhost:8009/)) {
        coEmpresa = 'modulo';
      } else if (url.match(/fass.edu.br/) || url.match(/localhost:8010/)) {
        coEmpresa = 'fass';
      } else if (url.match(/fsg.edu.br/) || url.match(/localhost:8011/)) {
        coEmpresa = 'fsg';
      } else if (url.match(/brazcubas.edu.br/) || url.match(/localhost:8012/)) {
        coEmpresa = 'brazcubas';
      } else if (url.match(/up.edu.br/) || url.match(/localhost:8013/)) {
        coEmpresa = "up";
      } else {
        coEmpresa = 'unicid';
      }

      $('body').addClass('bodyEmpresa_' + coEmpresa);

      function showNextAluno(name) {
        $('.form-e-mail-aluno [name="' + name + '"]').show();
        $('.fcatendimento-fcci-bloco-ctaop2._aluno').hide();
        validateForm('form-e-mail-aluno')
      }

      function showNextCandidato(name) {
        let modalidade = document.querySelector('.form-e-mail-candidato [name="maluas"]').value;
        if (name == 'fingresso' && modalidade !== 'Graduação') {
          name = 'assunto';
        }

        $('.form-e-mail-candidato [name="' + name + '"]').show();
        $('.fcatendimento-fcci-bloco-ctaop2._candidato').hide();
        validateForm('form-e-mail-candidato')
      }

      if (document.querySelector('.form-e-mail-candidato select[name="maluas"]')) {
        document.querySelector('.form-e-mail-candidato select[name="maluas"]').addEventListener('change', () => {
          carregarOpcoesCurso();
        });
      }

      function carregarOpcoesCurso() {
        let selectCurso = document.querySelector('.form-e-mail-candidato [name="curso"]');
        let selectModalidade = document.querySelector('.form-e-mail-candidato [name="maluas"]');
        let nonceGetCursosPesquisa = document.querySelector('.form-e-mail-candidato input[name="nonceGetCursosPesquisa"]');

        $.ajax({
          url: WpObj.ajaxurl,
          method: 'POST',
          data: {
            action: 'getCursos',
            modalidade: selectModalidade.value,
            nonce: nonceGetCursosPesquisa.value
          }
        }).done((dados) => {
          selectCurso.innerHTML = '';

          let optionPadrao = document.createElement('option');
          optionPadrao.setAttribute('disabled', true);
          optionPadrao.setAttribute('selected', true);
          optionPadrao.innerText = 'Curso';
          selectCurso.appendChild(optionPadrao);

          let opcoes = JSON.parse(dados);
          opcoes.forEach((opcao) => {
            let opcaoElemento = document.createElement('option');
            opcaoElemento.setAttribute('value', opcao['nomeCurso']);
            opcaoElemento.innerText = opcao['nomeCurso'];
            selectCurso.appendChild(opcaoElemento);
          });

          let optionCursoNaoEncontrado = document.createElement('option');
          optionCursoNaoEncontrado.setAttribute('value', 'nao_encontrou');
          optionCursoNaoEncontrado.innerText = 'Não encontrei meu curso';
          selectCurso.appendChild(optionCursoNaoEncontrado);
        });
      }

      function validateForm(typeForm) {
        let error = 0;
        $('.' + typeForm).find('select:not([style*="display: none"]), textarea:not([style*="display: none"]), input:not([style*="display: none"])').each(function () {
          if ($(this).prop('required')) {
            if (!$(this).val()) error = 1
          }
        });

        if (error == 0) $('.' + typeForm + ' .fcatendimento-fcci-bloco-formbtn').addClass('_activebtn');
        else $('.' + typeForm + ' .fcatendimento-fcci-bloco-formbtn').removeClass('_activebtn');
      }

      $("#fcatendimento-fc-nav-candidato").on('click', function () {
        // Candidato
        $('#fcatendimento-fc-nav a').removeClass('active');
        $(this).addClass('active');
        $('.fcatendimento-fc-conteudoitem').hide();
        $('.fcatendimento-fc-conteudoitem-canditato').show(300);
        return false;
      });

      $("#fcatendimento-fc-nav-aluno").on('click', function () {
        // Aluno
        $('#fcatendimento-fc-nav a').removeClass('active');
        $(this).addClass('active');
        $('.fcatendimento-fc-conteudoitem').hide();
        $('.fcatendimento-fc-conteudoitem-aluno').show(300);
        return false;
      });

      $("#fcatendimento-fc-nav-outros").on('click', function () {
        // Outros
        $('#fcatendimento-fc-nav a').removeClass('active');
        $(this).addClass('active');
        $('.fcatendimento-fc-conteudoitem').hide();
        $('.fcatendimento-fc-conteudoitem-opublicos').show(300);
        return false;
      });

      $("#fcatendimento-fc-nav-denuncias").on('click', function () {
        // Denuncias
        $('#fcatendimento-fc-nav a').removeClass('active');
        $(this).addClass('active');
        $('.fcatendimento-fc-conteudoitem').hide();
        $('.fcatendimento-fc-conteudoitem-cduvidas').show(300);
        return false;
      });

      $("#FCAtendimento #fcatendimento-fcci-bloco-snav").on('change', function () {
        // Item 1
        $('.comunidade-itens-ops ').hide();
        $('.comunidade-itens-op' + $(this).val()).show(300);
        return false;
      });

      $("#FCAtendimento #fcatendimento-fc-navmob").on('change', function () {

        if ($(this).val() === "Selecione uma das formas") {
          // Selecione uma das formas
          $('.fcatendimento-fc-conteudoitem').hide();
          $('#fcatendimento-fc-conteudo-fake').show(300);
          return false;

        } else if ($(this).val() === "Sou Candidato") {
          // Sou Candidato
          $('.fcatendimento-fc-conteudoitem').hide();
          $('.fcatendimento-fc-conteudoitem-canditato').show(300);
          return false;

        } else if ($(this).val() === "Sou Aluno") {
          // Sou Aluno
          $('.fcatendimento-fc-conteudoitem').hide();
          $('.fcatendimento-fc-conteudoitem-aluno').show(300);
          return false;

        } else if ($(this).val() === "Outros Públicos") {
          // Outros Públicos
          $('.fcatendimento-fc-conteudoitem').hide();
          $('.fcatendimento-fc-conteudoitem-opublicos').show(300);
          return false;

        } else if ($(this).val() === "Canal de Denúncias") {
          // Canal de Denúncias
          $('.fcatendimento-fc-conteudoitem').hide();
          $('.fcatendimento-fc-conteudoitem-cduvidas').show(300);
          return false;

        } else if ($(this).val() === "Sala de Imprensa") {
          if ($(this).attr('target') == '_blank') {
            $(window).attr('location', $(this).attr('href'))
          } else {
            window.open($(this).attr('href'), '_blank');
          }
        } else { }

      });

      $('#eu_sou_faq').on('change', function () {
        if ($(this).val() == 'Candidato') {
          $('#containerAlunoFields').hide();
          $('#containerAlunoFields select').hide();
          $('#containerOutrosFields').hide();
          $('#containerOutrosFields select').hide();
          $('#containerCandidatoFields').show();
          $('#matriculafaq').hide();
          // $('#maulasfaq').show();
        } else if ($(this).val() == 'Aluno') {
          $('#containerCandidatoFields').hide();
          $('#containerCandidatoFields select').hide();
          $('#containerOutrosFields').hide();
          $('#containerOutrosFields select').hide();
          $('#containerAlunoFields').show();
          $('#matriculafaq').show();
          $('#assuntofaq').show();
        } else if ($(this).val() == 'Outros') {
          $('#containerCandidatoFields').hide();
          $('#containerCandidatoFields select').hide();
          $('#containerAlunoFields').hide();
          $('#containerAlunoFields select').hide();
          $('#containerOutrosFields').show();
          $('#matriculafaq').hide();
          $('#assuntofaq').show();
        } else {
          $('#containerCandidatoFields').hide();
          $('#containerCandidatoFields select').hide();
          $('#containerAlunoFields').hide();
          $('#containerAlunoFields select').hide();
          $('#containerOutrosFields').hide();
          $('#containerOutrosFields select').hide();
        }
      });

      $('#containerCandidatoFields textarea').on('input', function () {
        $('.form-duvidas .fcatendimento-fcci-bloco-formbtn').addClass('_activebtn');
      });

      $('#containerAlunoFields textarea').on('input', function () {
        $('.form-duvidas .fcatendimento-fcci-bloco-formbtn').addClass('_activebtn');
      });

      $('#containerOutrosFields textarea').on('input', function () {
        $('.form-duvidas .fcatendimento-fcci-bloco-formbtn').addClass('_activebtn');
      });

      $('.btn_fechar_modal').on('click', function (e) {
        e.preventDefault();
        $('.mascara_modal').removeClass('showModal');
        $('body').removeClass('blockBody');
        $('html').removeClass('blockBody');
      });

      if ($('.mascara_modal').length) {
        $('body').addClass('blockBody');
        $('html').addClass('blockBody');
        $('.mascara_modal').addClass('showModal');
      }

      $('.btn_link_modal').on('click', function (e) {
        if (!$(this).attr('href')) {
          e.preventDefault();
        }
      });

      $('.Matutino #form_parcelas .line_disciplina input[data-oferta-manha="false"]').hide();
      $('.Noturno #form_parcelas .line_disciplina input[data-oferta-noite="false"]').hide();

      $('.line_disciplina label').on('click', function (e) {
        e.preventDefault();
      });

      const newLocal = window.location.href.indexOf("/graduacao/odontologia/");
      ((coEmpresa == 'fsg' || coEmpresa == 'cesuca') && newLocal > -1) ? $('.minimo_plano_pagamento_em_parcelas').html('Para o curso de odontologia é necessario selecionar todas as disciplinas para visualizar o valor do curso!') : null;

      $('.input-disciplinas').on('change', function (e) {
        if ($(this).prop('checked')) {
          var valorCredito = parseInt($('.valor_por_credito').val());
          var quantidadeSelecionada = parseInt($('.quantidade_selecionada').val());
          var quantidadeCreditos = parseInt($(this).data('creditos'));
          var quantidadeCreditosSelecionados = parseInt($('.quantidadeCreditosSelecionados').val());

          $('.quantidadeCreditosSelecionados').val(parseInt(quantidadeCreditosSelecionados + quantidadeCreditos));

          var conta = parseInt(valorCredito * $('.quantidadeCreditosSelecionados').val());
          var conta = parseInt(conta / 6);

          $('.valor_total').val(parseInt(conta));
          $('.quantidade_selecionada').val(++quantidadeSelecionada);
          if ($('.quantidade_selecionada').val() == 1) {
            $('.qtd_disciplinas').html('1 disciplina,');
          } else {
            $('.qtd_disciplinas').html(quantidadeSelecionada + ' disciplinas,');
          }
          $('.valor_parcela').html('R$ ' + parseInt(conta) + (parseInt(conta).includes(',') ? '*' : ',00*'));
          var atraso = parseInt(conta * 1.17);
          $('.obs').append(' Após a data: R$ ' + atraso + (atraso.includes(',') ? '' : ',00'));

          if ($('.quantidade_selecionada').val() >= 3) {
            $('.container_preco').show();
            $('.queroInsc').show();
          } else {
            $('.container_preco').hide();
            $('.queroInsc').hide();
          }
        } else {
          var valorCredito = parseInt($('.valor_por_credito').val());
          var quantidadeSelecionada = parseInt($('.quantidade_selecionada').val());
          var quantidadeCreditos = parseInt($(this).data('creditos'));
          var quantidadeCreditosSelecionados = parseInt($('.quantidadeCreditosSelecionados').val());

          $('.quantidadeCreditosSelecionados').val(parseInt(quantidadeCreditosSelecionados - quantidadeCreditos));

          var conta = parseInt(valorCredito * $('.quantidadeCreditosSelecionados').val());
          var conta = parseInt(conta / 6);

          $('.valor_total').val(parseInt(conta));
          $('.quantidade_selecionada').val(--quantidadeSelecionada);
          if ($('.quantidade_selecionada').val() == 1) {
            $('.qtd_disciplinas').html('1 disciplina,');
          } else if ($('.quantidade_selecionada').val() == 0) {
            $('.qtd_disciplinas').html('0 disciplina,');
          } else {
            $('.qtd_disciplinas').html(quantidadeSelecionada + ' disciplinas,');
          }
          $('.valor_parcela').html('R$ ' + parseInt(conta) + (parseInt(conta).includes(',') ? '*' : ',00*'));
          var atraso = parseInt(conta * 1.17);
          $('.obs').append(' Após a data: R$ ' + atraso + (atraso.includes(',') ? '' : ',00'));

          if ($('.quantidade_selecionada').val() >= 3) {
            $('.container_preco').show();
            $('.queroInsc').show();
          } else {
            $('.container_preco').hide();
            $('.queroInsc').hide();
          }
        }
      });

      $('.btn-busca-topo').click(function (e) {
        e.preventDefault();
      });

      $('.checklista').change(function () {
        listUncheked = '';
        $(".checklista:checkbox:not(:checked)").each(function () {
          listUncheked += $(this).val() + '!';
        });
        var arr = [];
        var link = arr.toString();
        var link = link.replace(/,/g, "&");
        var link = "/?" + link;
        var url = window.location.protocol + "//" + window.location.host + window.location.pathname;
        filtros = "?filtros=" + listUncheked;
        window.location.replace(url + filtros);
      });

      // Informações rápidas - Formas de ingresso
      $('.combo-formas-ingresso-ead').hide();

      $(".n-privacidade-menu-lista li a").on("click", function (e) {
        $(".n-privacidade-menu-lista li a").removeClass("active");
        $(this).addClass("active");
      });

      $(".n-privacidade-politicas-item").click(function () {
        if ($(this).next().hasClass("active-painel")) {
          $(this).next().removeClass("active-painel");
        }
        if ($(this).hasClass("active-item")) {
          $(".n-privacidade-politicas-item").removeClass("active-item");
        } else {
          $(".n-privacidade-politicas-item").removeClass("active-item");
          $(".n-privacidade-politicas-painel").removeClass("active-painel");
          $(this).next().addClass("active-painel");
          $(this).addClass("active-item");
        }
      });

      $(".n-ead-privacidade-popup-aceito").click(function () {
        $(".n-ead-privacidade-popup").hide();
      });

      $(".n-ead-privacidade-popup-saiba-mais").click(function () {
        $(".n-ead-privacidade-popup").hide();
      });

      $(".n-privacidade-menu-titulo").click(function () {
        $(".n-privacidade-politicas-painel").removeClass("active-painel");
        $(".n-privacidade-politicas-item").removeClass("active-item");
        $('html,body').animate({ scrollTop: $("#ppconteudo").offset().top - 100 }, 500);
      });

      // Formas de Ingresso - Navegação Mobile
      $(".formasingresso-nav-mobile #formasingresso-nav").change(function () {
        if ($(this).val() === "Selecione uma das formas") {
          // Selecione uma das formas - Fake
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-fake').show();
          return false;

        } else if ($(this).val() === "Processo Seletivo") {
          // Processo Seletivo
          history.replaceState({}, null, './');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-vestibular').show();
          return false;

        } else if ($(this).val() === "Graduação") {
          // Graduação (Portal EAD)
          history.replaceState({}, null, './');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-vestibular').show();
          return false;

        } else if ($(this).val() === "Medicina: Vestibular") {
          // Medicina: Vestibular
          history.replaceState({}, null, './medicina');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-medicina').show();
          return false;

        } else if ($(this).val() === "Medicina: Transferência e Segunda Graduação") {
          // Medicina: Transferência
          history.replaceState({}, null, './medicina-transferencia');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-medicinatransf').show();
          return false;

        } else if ($(this).val() === "Segunda Graduação") {
          // Segunda Graduação
          history.replaceState({}, null, './segunda-graduacao');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-segunda-graduacao').show();
          return false;

        } else if ($(this).val() === "Transferência") {
          // Transferência
          history.replaceState({}, null, './transferencia');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-transferencia').show();
          return false;

        } else if ($(this).val() === "Formação de Professores") {
          // Formação de Professores (Portal EAD)
          history.replaceState({}, null, './professores');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-formacao-de-professores').show();
          return false;

        } else if ($(this).val() === "Pós-Graduação") {
          // Pós-Graduação (Portal EAD)
          history.replaceState({}, null, './pos-graduacao');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-pos-graduacao').show();
          return false;

        } else if ($(this).val() === "Cursos Livres") {
          // Cursos Livres (Portal EAD)
          history.replaceState({}, null, './cursos-livres');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-cursos-livres').show();
          return false;

        } else if ($(this).val() === "Cursos Técnicos") {
          // Cursos Técnicos (Portal EAD)
          history.replaceState({}, null, './cursos-tecnicos');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-cursos-tecnicos').show();
          return false;

        } else if ($(this).val() === "EAD") {
          // EAD
          history.replaceState({}, null, './ead');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-eadsemi').show();
          return false;

        } else if ($(this).val() === "Retorne ao curso") {
          // Retorne ao curso
          history.replaceState({}, null, './retorne-curso');
          //Aba da cruzeiro do sul virtual ?
          if ($(this).children(":selected").attr("id").indexOf('retorne-ao-curso-ead') >= 0) {
            $('.formasingresso-conteudo-item').hide();
            $('.formasingresso-conteudo-retorne-ao-curso').show();
          } else {
            $('.formasingresso-conteudo-item').hide();
            $('.formasingresso-conteudo-exalunos').show();
          }
          return false;
        } else { }

      }).change();

      // Formas de Ingresso - Navegação Desktop
      $("#formasingresso-nav-vestibular").click(function () {
        // Processo Seletivo
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-vestibular').show();
        return false;
      });

      $("#formasingresso-nav-graduacao").click(function () {
        // Graduação (Portal EAD)
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-vestibular').show();
        return false;
      });

      $("#formasingresso-nav-medicina").click(function () {
        // Medicina: Vestibular
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-medicina').show();
        return false;
      });

      $("#formasingresso-nav-medicinaTransferencia").click(function () {
        // Medicina: Transferência
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-medicinatransf').show();
        return false;
      });

      $('#formasingresso-nav-secgraduacao').click(function () {
        // Segunda Graduação
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-segunda-graduacao').show();
        return false;
      });

      $('#formasingresso-nav-transferencia').click(function () {
        // Transferência
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-transferencia').show();
        return false;
      });

      $('#formasingresso-nav-formacao-de-professores').click(function () {
        // Formação de Professores (Portal EAD)
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-formacao-de-professores').show();
        return false;
      });

      $('#formasingresso-nav-pos-graduacao').click(function () {
        // Pós-Graduação (Portal EAD)
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-pos-graduacao').show();
        return false;
      });

      $('#formasingresso-nav-cursos-livres').click(function () {
        // Cursos Livres (Portal EAD)
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-cursos-livres').show();
        return false;
      });

      $('#formasingresso-nav-cursos-tecnicos').click(function () {
        // Cursos Técnicos (Portal EAD)
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-cursos-tecnicos').show();
        return false;
      });

      $('#formasingresso-nav-onlinesemi').click(function () {
        // EAD e Semipresencial
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-eadsemi').show();
        return false;
      });

      $('#formasingresso-nav-exalunos').click(function () {
        // Retorne ao curso
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-exalunos').show();
        return false;
      });

      // Formas de Ingresso - Navegação Desktop
      $("#formasingresso-nav-vestibular").click(function () {
        // Processo Seletivo
        history.replaceState({}, null, './');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-vestibular').show();
        return false;
      });

      $("#formasingresso-nav-graduacao").click(function () {
        // Graduação (Portal EAD)
        history.replaceState({}, null, './');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-vestibular').show();
        return false;
      });

      $("#formasingresso-nav-medicina").click(function () {
        // Medicina: Vestibular
        history.replaceState({}, null, './medicina');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-medicina').show();
        return false;
      });

      $("#formasingresso-nav-medicinaTransferencia").click(function () {
        // Medicina: Transferência
        history.replaceState({}, null, './medicina-transferencia');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-medicinatransf').show();
        return false;
      });

      $('#formasingresso-nav-secgraduacao').click(function () {
        // Segunda Graduação
        history.replaceState({}, null, './segunda-graduacao');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-segunda-graduacao').show();
        return false;
      });

      $('#formasingresso-nav-transferencia').click(function () {
        // Transferência
        history.replaceState({}, null, './transferencia');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-transferencia').show();
        return false;
      });

      $('#formasingresso-nav-formacao-de-professores').click(function () {
        // Formação de Professores (Portal EAD)
        history.replaceState({}, null, './professores');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-formacao-de-professores').show();
        return false;
      });

      $('#formasingresso-nav-pos-graduacao').click(function () {
        // Pós-Graduação (Portal EAD)
        history.replaceState({}, null, './pos-graduacao');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-pos-graduacao').show();
        return false;
      });

      $('#formasingresso-nav-cursos-livres').click(function () {
        // Cursos Livres (Portal EAD)
        history.replaceState({}, null, './cursos-livres');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-cursos-livres').show();
        return false;
      });

      $('#formasingresso-nav-cursos-tecnicos').click(function () {
        // Cursos Técnicos (Portal EAD)
        history.replaceState({}, null, './cursos-tecnicos');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-cursos-tecnicos').show();
        return false;
      });

      $('#formasingresso-nav-retorne-ao-curso-ead').click(function () {
        // Retorne ao Curso (Portal EAD)
        history.replaceState({}, null, './retorne-curso');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-retorne-ao-curso').show();
        return false;
      });

      $('#formasingresso-nav-onlinesemi').click(function () {
        // EAD e Semipresencial
        history.replaceState({}, null, './ead');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-eadsemi').show();
        return false;
      });

      $('#formasingresso-nav-exalunos').click(function () {
        // Retorne ao curso
        history.replaceState({}, null, './retorne-curso');
        $('.formasingresso-nav a').removeClass('active');
        $(this).addClass('active');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-exalunos').show();
        return false;
      });

      // Formas de Ingresso - Define links para abertura da página direto nas abas
      if (window.location.href.indexOf("processo-seletivo/graduacao/segunda-graduacao") > -1) {
        //Segunda Graduação Virtual
        $('.formasingresso-nav a').removeClass('active');
        $('#formasingresso-nav-secgraduacao').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Segunda Graduação');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-segunda-graduacao').show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao/professores") > -1) {
        //Formação de Professores Virtual
        $('.formasingresso-nav a').removeClass('active');
        $('#formasingresso-nav-formacao-de-professores').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Formação de Professores');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-formacao-de-professores').show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao/pos-graduacao") > -1) {
        //Pós-Graduação Virtual
        $('.formasingresso-nav a').removeClass('active');
        $('#formasingresso-nav-pos-graduacao').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Pós-Graduação');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-pos-graduacao').show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao/cursos-livres") > -1) {
        //Cursos Livres Virtual
        $('.formasingresso-nav a').removeClass('active');
        $('#formasingresso-nav-cursos-livres').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Cursos Livres');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-cursos-livres').show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao/cursos-tecnicos") > -1) {
        //Cursos Técnicos Virtual
        $('.formasingresso-nav a').removeClass('active');
        $('#formasingresso-nav-cursos-tecnicos').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Cursos Técnicos');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-cursos-tecnicos').show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao/retorne-curso") > -1) {
        //Retorne ao Curso Virtual
        $('.formasingresso-nav a').removeClass('active');
        $('#formasingresso-nav-retorne-ao-curso-ead').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Retorne ao curso');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-retorne-ao-curso').show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao/transferencia") > -1) {
        //Transferência Virtual
        $('.formasingresso-nav a').removeClass('active');
        $('#formasingresso-nav-transferencia').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Transferência');
        $('.formasingresso-conteudo-item').hide();
        $('.formasingresso-conteudo-transferencia').show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao-presencial/transferencia") > -1) {
        //Transferência Presencial
        $('#formasingresso-nav li a').removeClass('active');
        $('#formasingresso-nav-transferencia').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Transferência');
        $('.formasingresso-conteudo-item').css('display', 'none');
        $("#formasingresso-conteudo-Transferencia").show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao-presencial/segunda-graduacao") > -1) {
        //Segunda Graduação Presencial
        $('#formasingresso-nav li a').removeClass('active');
        $('#formasingresso-nav-secgraduacao').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Segunda Graduação');
        $('.formasingresso-conteudo-item').css('display', 'none');
        $("#formasingresso-conteudo-SecGraduacao").show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao-presencial/medicina-transferencia") > -1) {
        // Medicina: Transferência - Presencial
        $('#formasingresso-nav li a').removeClass('active');
        $('#formasingresso-nav-medicinaTransferencia').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Medicina: Transferência e Segunda Graduação');
        $('.formasingresso-conteudo-item').css('display', 'none');
        $("#formasingresso-conteudo-MedicinaTransferencia").show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao-presencial/medicina") > -1) {
        // Medicina: Vestibular - Presencial
        $('#formasingresso-nav li a').removeClass('active');
        $('#formasingresso-nav-medicina').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Medicina: Vestibular');
        $('.formasingresso-conteudo-item').css('display', 'none');
        $("#formasingresso-conteudo-Medicina").show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao-presencial/retorne-curso") > -1) {
        //Retorne ao Curso Presencial
        $('#formasingresso-nav li a').removeClass('active');
        $('#formasingresso-nav-exalunos').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('Retorne ao curso');
        $('.formasingresso-conteudo-item').css('display', 'none');
        $("#formasingresso-conteudo-Exalunos").show();

      } else if (window.location.href.indexOf("processo-seletivo/graduacao-presencial/ead") > -1) {
        //EAD Presencial
        $('#formasingresso-nav li a').removeClass('active');
        $('#formasingresso-nav-onlinesemi').addClass('active');
        $(".formasingresso-nav-mobile #formasingresso-nav").val('EAD');
        $('.formasingresso-conteudo-item').css('display', 'none');
        $("#formasingresso-conteudo-OnlineSemi").show();

      } else {
        if ($('#formasingresso-nav-vestibular').length) {
          //Graduação Presencial
          $('#formasingresso-nav li a').removeClass('active');
          $('#formasingresso-nav-vestibular').addClass('active');
          $(".formasingresso-nav-mobile #formasingresso-nav").val('Processo Seletivo');
          $('.formasingresso-conteudo-item').hide();
          $(".formasingresso-conteudo-vestibular").show();

        } else if ($('#formasingresso-nav-graduacao').length) {
          //Graduação EAD
          $('#formasingresso-nav li a').removeClass('active');
          $('#formasingresso-nav-graduacao').addClass('active');
          $(".formasingresso-nav-mobile #formasingresso-nav").val('Graduação');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-vestibular').show();

        } else {
          // Selecione - Fake
          $('#formasingresso-nav li a').removeClass('active');
          $(".formasingresso-nav-mobile #formasingresso-nav").val('Selecione uma das formas');
          $('.formasingresso-conteudo-item').hide();
          $('.formasingresso-conteudo-fake').show();
        }
      }

    });
    // END - Módulo Formas de Ingresso

    function getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0)
          return c.substring(name.length, c.length);
      }
      return "";
    }

    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;HttpOnly;Secure";
    }

    $.fn.isInViewport = function () {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    !(function (t) {
      var i = t(window);
      t.fn.visible = function (t, e, o) {
        if (!(this.length < 1)) {
          var r = this.length > 1 ? this.eq(0) : this,
            n = r.get(0),
            f = i.width(),
            h = i.height(),
            o = o ? o : 'both',
            l = e === !0 ? n.offsetWidth * n.offsetHeight : !0;
          if ('function' == typeof n.getBoundingClientRect) {
            var g = n.getBoundingClientRect(),
              u = g.top >= 0 && g.top < h,
              s = g.bottom > 0 && g.bottom <= h,
              c = g.left >= 0 && g.left < f,
              a = g.right > 0 && g.right <= f,
              v = t ? u || s : u && s,
              b = t ? c || a : c && a;
            if ('both' === o) return l && v && b;
            if ('vertical' === o) return l && v;
            if ('horizontal' === o) return l && b;
          } else {
            var d = i.scrollTop(),
              p = d + h,
              w = i.scrollLeft(),
              m = w + f,
              y = r.offset(),
              z = y.top,
              B = z + r.height(),
              C = y.left,
              R = C + r.width(),
              j = t === !0 ? B : z,
              q = t === !0 ? z : B,
              H = t === !0 ? R : C,
              L = t === !0 ? C : R;
            if ('both' === o) return !!l && p >= q && j >= d && m >= L && H >= w;
            if ('vertical' === o) return !!l && p >= q && j >= d;
            if ('horizontal' === o) return !!l && m >= L && H >= w;
          }
        }
      };
    })(jQuery);

    /* Função Filtros de Busca*/
    function criaPaginacao() {
      var totalResultados = $('section.exibirResultado').length;
      var paginas = Math.ceil(totalResultados / 10);
      $('.classResultado').removeClass('grey');
      var html = '';
      if (paginas > 1) {
        html = '<ul class="paginacaoResultado">'
        for (var i = 1; i <= paginas; i++) {
          if (i == 1) {
            html += '<li><a href="javascript:void(0)" id="btnPaginacao-' + i + '" class="btn classBtnPaginacao paginacaoAtivo" data-pagina="' + i + '">' + i + '</a></li>';
          } else {
            html += '<li><a href="javascript:void(0)" id="btnPaginacao-' + i + '" class="btn classBtnPaginacao" data-pagina="' + i + '">' + i + '</a></li>';
          }
        }
        html += "</ul>";
      }
      if (totalResultados == 0) {
        html = '<p class="leftTextoPaginacao">Não encontramos nenhum resultado pelo filtro selcionado.</p>';
      } else if (totalResultados == 1) {
        html += '<p class="leftTextoPaginacao">Encontramos apenas <strong>um</strong> resultado pelo filtro selecionado.';
      } else {
        html += '<p  class="leftTextoPaginacao">Encontramos <strong>' + totalResultados + '</strong> resultados pelo filtro selecionado.';
      }
      $('.divPaginacao').html(html);

    }
    function carregarConteudoPagina(pagina) {
      $('.classBtnPaginacao').removeClass('paginacaoAtivo');
      $('#btnPaginacao-' + pagina).addClass('paginacaoAtivo');

      $('.classResultado').hide();
      var inicio = (pagina - 1) * 10;
      var fim = pagina * 10;
      var indice = '';
      var classeUtilizada = 'grey';
      $('.exibirResultado').each(function (indexPagina) {
        if (indexPagina >= inicio && indexPagina < fim) {
          indice = $(this).attr('id');
          indice = indice.split('-');
          $('#resultado-' + indice[1]).fadeIn();
          classeUtilizada = classeUtilizada == 'white' ? 'grey' : 'white';
          $('#resultado-' + indice[1] + '>div').removeClass('grey', 'white').addClass(classeUtilizada);
        }
      });
    }

    (function ($) {
      $(document).ready(function () {
        criaPaginacao();
        carregarConteudoPagina(1);

        $('.classBtnPaginacao').on('click', function () {
          carregarConteudoPagina($(this).attr('data-pagina'));
        })

        $('.checklista').on('click', function () {

          $('.classResultado').addClass('exibirResultado').show();

          if (!$('.check-curso').is(':checked')) {
            $('.cursos').removeClass('exibirResultado').hide();
          }
          if (!$('.check-noticia').is(':checked')) {
            $('.noticias').removeClass('exibirResultado').hide();
          }
          if (!$('.check-beneficios').is(':checked')) {
            $('.modulo').removeClass('exibirResultado').hide();
          }
          if (!$('.check-instituicao').is(':checked')) {
            $('.modulo').removeClass('exibirResultado').hide();
          }

          setTimeout(criaPaginacao(), 1000);
          setTimeout(carregarConteudoPagina(1), 1500);

        });

      });

      $('.close-materia').on('click', function () {
        $(this).parent().parent().toggleClass('active');
        $(this).text() == '+' ? $(this).text('-') : $(this).text('+')
      });

      $('.close-modal-default').click(function () {
        $(this).parent().parent().removeClass('active');
      });

      $('.btn-inicio-doc').click(function () {
        $('.modal-doc-inicio').addClass('active');
      });

      $('.card-campus-slider').click(function () {
        let id = $(this).attr('modal-id')
        $('#campus-modal-' + id).addClass('active');
      });

      $('#open-video-coordenacao').click(function () {
        $('#modal-video-coordenacao').addClass('active');
      });

      $('#open-modal-video-sidebar').click(function () {
        $('#modal-video-sidebar').addClass('active');
      });

      $('.open-doc-necessario').click(function () {
        $('.docnecessario-modal').addClass('active');
      });

      $('#open-video-sidebar-pl').click(function () {
        $('#video-sidebar-pl').addClass('active');
      });

      $('.close-slider-campus').click(function () {
        $(this).parent().removeClass('active');
      });

      $('.btn-valor-curso').click(function (event) {
        event.preventDefault();

        let cont = 0;
        $(".select-field-icon select").each(function () {
          if ($(this).val() == '') {
            cont++;
            $(this).addClass('_field-error');
          }
        });

        if (cont == 0 || $(this).hasClass('no-validation')) {
          $(this).hide('slow');
          $('.check-campus-validation').hide('slow');
          $('.get-lead-valor-curso').show('slow');
        }
      });

      $('.select-field-icon select').on('change', function () {
        if ($(this).val() != '') $(this).removeClass('_field-error')

        let cont = 0;
        $(".select-field-icon select").each(function () {
          if ($(this).val() == '') cont++;
        });

        if (cont == 0) {
          let valCampus = $(".select-field-icon select#val-campus").val();
          let valInicio = $(".select-field-icon select#val-inicio").val();
          let codCurs = $("input#val-idCurso").val();

          $.ajax({
            url: '/wp-json/data-course/pdpdata?campus=' + valCampus + '&inicio=' + valInicio + '&codCurs=' + codCurs,
            method: "GET"
          }).done(function (data) {
            insertDataCourse(data[0]);
          })
        }
      });

      function insertDataCourse(data) {
        if (!!data.dataInicioInscricao && !!data.dataFimInscricao) {
          $('.item-inscricoes p span').text(`De ${formatData(data.dataInicioInscricao)} a ${formatData(data.dataFimInscricao)}`);
        } else { $('.item-inscricoes').hide(); }

        if (!!data.dataInicioMatricula && !!data.dataFimMatricula) {
          $('.item-matriculas p span').text(`De ${formatData(data.dataInicioMatricula)} a ${formatData(data.dataFimMatricula)}`);
        } else { $('.item-matriculas').hide(); }

        if (!!data.dataInicioAulas && !!data.dataFimAulas) {
          $('.item-aulas p span').text(`De ${formatData(data.dataInicioAulas)} a ${formatData(data.dataFimAulas)}`);
        } else { $('.item-aulas').hide(); }

        if (!!data.horario) {
          $('.item-horario p span').text(data.horario);
        } else { $('.item-horario').hide(); }

        if (!!data.duracao) {
          $('.item-duracao p span').text(data.duracao);
        } else { $('.item-duracao').hide(); }

        if (!!data.endereco) {
          $('.item-local p span').text(data.endereco);
        } else { $('.item-local').hide(); }

        if (!!data.observacaoCursoTurma) {
          $('.message-valor-pdp').text(data.observacaoCursoTurma);
        } else { $('.message-valor-pdp').hide(); }

        if (!!data.taxadeInscricao) {
          $('.item-tax-insc b').text(`R$ ${data.taxadeInscricao}`);
        } else { $('.item-tax-insc').hide(); }

        if (!!data.valorTotalCursoTurma) {
          $('.item-valor-total').text(`R$ ${data.valorTotalCursoTurma}`);
        } else { $('.item-valor-total').hide(); }

        if (!!data.parcelamentoCartao) {
          $('.item-parcelas').text(`+ ${data.parcelamentoCartao}x de`);
        } else { $('.item-parcelas').hide(); }
        $('.content-info-course').addClass('active');
      }

      function formatData(data) {
        data = data.split(' ')[0]
        const ano = data.split('-')[0]
        const mes = data.split('-')[1]
        const dia = data.split('-')[2]
        return `${dia}/${mes}/${ano}`
      }

      $('.check-campus-validation').click(function (event) {
        event.preventDefault();
        let cont = 0;
        $(".select-field-icon select").each(function () {
          if ($(this).val() == '') {
            cont++;
            $(this).addClass('_field-error');
          }
        });

        if ($(this).hasClass('no-validation')) {
          window.location.href = 'https://inscricoes.cruzeirodosulvirtual.com.br/s/ficha-de-inscricao';
        } else if (cont == 0) {
          window.location.href = '/processo-seletivo/graduacao-presencial/?link=bVestibular'
        }
      });

    })(jQuery);

  }, 500);
})();